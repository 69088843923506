import React from "react";
import { Link } from "react-router-dom";

const ActionBox = () => {
  return (
    <section className="space-pb dark-background">
      <div className="container">
        <div className="bg-dark text-center rounded py-5 px-3">
          <h2 className="text-white">
            Tell us about your idea, and we’ll make it happen.
          </h2>
          <h6 className="text-white">
            Have a brand problem that needs to be solved? We’d love to hear
            about it!
          </h6>
          <Link
            to="/contact"
            className="btn btn-primary-round btn-round mx-0 mx-md-3 text-white"
          >
            Let’s Get Started
            <i className="fas fa-arrow-right ps-3" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ActionBox;
