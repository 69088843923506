import React from "react";
import ActionBox from "../components/ActionBox";

const Service = () => {
  return (
    <div>
      <section className="header-inner bg-overlay-black-50 headerImgAlt">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="header-inner-title text-center position-relative">
                <h1 className="text-white fw-normal">
                  Digital Marketing Services
                </h1>
                <p className="text-white mb-0">
                  Award-winning website design &amp; creative digital agency
                  services
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-data" />
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Content Creation
                  </h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    Lily Solutions creates professional and creative
                    photographic and videographic content that will attract
                    prospective clients to your page and drive engagement.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/01.jpg")',
                  }}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-author" />
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Social Media Management
                  </h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    Your digital space will be fully managed by our team as we
                    would Publish authorized posts, make posts that are engaging
                    based on available content, increase post engagements, and
                    respond to page engagements and/or orders.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/02.jpg")',
                  }}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-icon-149196" />
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Online Advertisement
                  </h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    This is the most reliable way to advertise online. This is
                    not a quest for views but a quest to market your product or
                    services till the prospect patronizes your product.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/03.jpg")',
                  }}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-chart" />
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Customer Experience
                  </h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    We interface with every client or prospective client that
                    contacts you to make sure they are satisfied with your
                    services. this is done to convert a majority of the outreach
                    to sales.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/04.jpg")',
                  }}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-design" />
                  <h5 className="mb-0 ms-4 feature-info-title">Lilyadd</h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    For online marketing, Lily Add is the first platform to run
                    sponsored WhatsApp adverts. This is not a Call-to-action
                    (CTA) online advert that leads prospects to WhatsApp chat,
                    these adverts run through our vast database of over five
                    thousand (5000) online users or freelancers who are given
                    tasks to execute daily.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/05.jpg")',
                  }}
                /> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-info feature-info-style-02 h-100">
                <div className="feature-info-icon">
                  <i className="flaticon-group" />
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Webcasts/ Video cast
                  </h5>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    Lily Solutions Limited will assist in organizing and oversee
                    the coordination of quarterly webcast for Clients. Based on
                    the type of webcast you would like (video, conference,
                    various locations), we will gather proposals and negotiate
                    pricing with various webcast vendors. We will utilize our
                    propriety database as well as all resources available to us
                    to ensure that we attract your target audience
                    to&nbsp;your&nbsp;business.
                  </p>
                  {/* <a href="service-detail.html" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
                {/* <div
                  className="feature-info-bg-img"
                  style={{
                    backgroundImage: 'url("images/feature-info/06.jpg")',
                  }}
                /> */}
              </div>
            </div>
          </div>
          {/* <div className="row mt-5">
            <div className="col-12 d-md-flex justify-content-center align-items-center">
              <p className="mb-3 mb-md-0 mx-0 mx-md-3">
                Start now! Your big opportunity may be right where you are!
              </p>
              <a
                href="#"
                className="btn btn-primary-round btn-round mx-0 mx-md-3"
              >
                See All Services
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <ActionBox />
    </div>
  );
};

export default Service;
