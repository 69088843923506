import React from "react";
import HeaderImg from "../components/HeaderImg";
import how1 from "../assets/images/how1.jpg";
import how2 from "../assets/images/how2.jpg";
import { Link } from "react-router-dom";
import CBoxes from "../components/CBoxes";

const Howwework = () => {
  return (
    <div>
      <HeaderImg
        title="How we work"
        disc="Make a list of your achievements toward your long-term goal and remind yourself that intentions don’t count, only action’s."
      />
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-title text-center">
                <h2>
                  Turning Vision into Reality
                  <p />
                  <p>
                    : Our approach is designed to ensure success at every step.
                    We combine strategic thinking, creative innovation, and
                    practical execution to deliver exceptional results for our
                    clients. Here's how we work:
                  </p>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-4 mb-sm-0">
              <img
                className="img-fluid border-radius"
                src={how1}
                alt=""
                style={{ height: 400, width: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="col-sm-6">
              <img
                className="img-fluid border-radius"
                src={how2}
                alt=""
                style={{ height: 400, width: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-title is-sticky">
                <h2>Smart strategy &amp; excellent performance</h2>
                <p className="mb-4 mb-md-5">
                  Process that guarantees high productivity and profitability
                  for your solution.
                </p>
                <Link to="/contact" className="btn btn-light-round btn-round">
                  Let's Start Project
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="feature-info feature-info-style-08">
                <div className="feature-info-inner">
                  <div className="feature-info-item">
                    <div className="feature-info-number">
                      <span>01</span>
                    </div>
                    <div className="feature-info-content">
                      <h5 className="mb-3 feature-info-title">
                        Understanding Your Needs
                      </h5>
                      <p className="mb-0">
                        We start by deeply understanding your business goals,
                        challenges, and vision. Through detailed consultations,
                        we ensure that we’re aligned with your objectives from
                        the outset.
                      </p>
                    </div>
                  </div>
                  <div className="feature-info-item">
                    <div className="feature-info-number">
                      <span>02</span>
                    </div>
                    <div className="feature-info-content">
                      <h5 className="mb-3 feature-info-title">
                        Strategic Planning
                      </h5>
                      <p className="mb-0">
                        Our team develops a customized strategy tailored to your
                        specific needs. We outline clear steps and milestones,
                        ensuring a roadmap that guides us to your desired
                        outcome.
                      </p>
                    </div>
                  </div>
                  <div className="feature-info-item">
                    <div className="feature-info-number">
                      <span>03</span>
                    </div>
                    <div className="feature-info-content">
                      <h5 className="mb-3 feature-info-title">
                        Creative Execution
                      </h5>
                      <p className="mb-0">
                        With a solid plan in place, we bring our creative and
                        technical expertise to the forefront. Whether it's
                        digital marketing, web development, or branding, we
                        execute with precision and innovation.
                      </p>
                    </div>
                  </div>
                  <div className="feature-info-item">
                    <div className="feature-info-number">
                      <span>04</span>
                    </div>
                    <div className="feature-info-content">
                      <h5 className="mb-3 feature-info-title">
                        Collaborative Approach
                      </h5>
                      <p className="mb-0">
                        We believe in the power of collaboration. Throughout the
                        project, we maintain open communication with you,
                        ensuring transparency and fostering a partnership that
                        drives success.
                      </p>
                    </div>
                  </div>
                  <div className="feature-info-item">
                    <div className="feature-info-number">
                      <span>05</span>
                    </div>
                    <div className="feature-info-content">
                      <h5 className="mb-3 feature-info-title">
                        Measurable Results
                      </h5>
                      <p className="mb-0">
                        Our work doesn’t stop at execution. We track progress,
                        measure results, and make data-driven adjustments to
                        optimize outcomes. Your success is our priority, and
                        we’re committed to delivering tangible, impactful
                        results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-title text-center">
                <h2>Choose the plan that works for you</h2>
                <p>
                  We offer flexible service packages designed to meet the
                  diverse needs of our clients. Select the plan that best aligns
                  with your business goals and budget.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing">
                <h4 className="pricing-title">Basic</h4>
                <p className="mb-0">
                  Only the basics - Everything you need to get started. Best for
                  startup
                </p>
                <span className="pricing-price">
                  <sup>
                    <s>N</s>
                  </sup>
                  <strong>500k</strong>
                  /month
                </span>
                <ul className="list-unstyled pricing-list">
                  <li>
                    Entry-level digital marketing and advertising services
                  </li>
                  <li>Standard website design and maintenance</li>
                  <li>Basic branding services including logo design/li&gt;</li>
                  <li>Initial business strategy consultation</li>
                  <li>Limited media relations support</li>
                </ul>
                <Link to="/contact" className="btn btn-light-round btn-round">
                  Select Plan
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing active">
                <h4 className="pricing-title">Standard</h4>
                <p className="mb-0">
                  When you are ready to grow, We will grow with you. Get a
                  Standard plan
                </p>
                <span className="pricing-price">
                  <sup>
                    <s>N</s>
                  </sup>
                  <strong>900k</strong>
                  /month
                </span>
                <ul className="list-unstyled pricing-list">
                  <li> Comprehensive digital marketing campaigns</li>
                  <li> Custom website development with enhanced features</li>
                  <li>
                    {" "}
                    Full branding services including visual identity and brand
                    collateral
                  </li>
                  <li> Ongoing business strategy and support</li>
                  <li> Media relations and public relations strategy</li>
                </ul>
                <Link to="/contact" className="btn btn-light-round btn-round">
                  Select Plan
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing">
                <h4 className="pricing-title">Professional</h4>
                <p className="mb-0">
                  Ready to kick it up a notch? Go for big with Professional
                  membership
                </p>
                <span className="pricing-price">
                  <sup>
                    <s>N</s>
                  </sup>
                  <strong>1.5M</strong>
                  /month
                </span>
                <ul className="list-unstyled pricing-list">
                  <li>Advanced digital marketing and advertising strategies</li>
                  <li>
                    Premium web development including e-commerce solutions
                  </li>
                  <li>Extensive branding services including UI/UX design</li>
                  <li>Full-scale business support and consultancy</li>
                  <li>Comprehensive media relations and crisis management</li>
                </ul>
                <Link to="/contact" className="btn btn-light-round btn-round">
                  Select Plan
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb our-clients">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 mb-md-0">
              <h5 className="text-primary mb-0">Awards and Nominees</h5>
            </div>
            <div className="col-xl-9 col-md-8">
              <div
                className="owl-carousel"
                data-nav-arrow="false"
                data-items={5}
                data-md-items={5}
                data-sm-items={4}
                data-xs-items={3}
                data-xx-items={2}
                data-space={40}
                data-autoheight="true"
              >
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/01.svg"
                    alt=""
                  />
                </div>
                {/* <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/02.svg"
                    alt=""
                  />
                </div> */}
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/03.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/04.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/05.svg"
                    alt=""
                  />
                </div>
                {/* <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/06.svg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <CBoxes />
    </div>
  );
};

export default Howwework;
