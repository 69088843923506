import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import vid from "../assets/videos/vid2.mp4";

const Banner = () => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.playbackRate = 0.7;
  }, []);

  return (
    <section className="banner">
      <div className="banner__main">
        <video autoPlay loop muted className="banner__video" ref={videoRef}>
          <source src={vid} />
        </video>
        <div className="banner__video__filter"></div>
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-9 col-md-10 text-center position-relative">
              <h1
                data-swiper-animation="fadeInUp"
                data-duration="1s"
                data-delay="0.25s"
              >
                A BUSINESS SUPPORT COMPANY YOU CAN TRUST
              </h1>
              <h6
                data-swiper-animation="fadeInUp"
                data-duration="1s"
                data-delay="0.5s"
              >
                We're Lilysolutions. We help drive change with technology
              </h6>
              <Link
                className="btn btn-dark btn-round text-white"
                data-swiper-animation="fadeInUp"
                data-duration="1s"
                data-delay="0.75s"
                to="/contact"
              >
                Get Started Now
                <i className="fas fa-arrow-right ps-3" />
              </Link>
            </div>
          </div>
          {/* <div
            className="swiper-slide align-items-center d-flex responsive-overlap-md bg-overlay-black-30"
            style={{
              backgroundImage: "url(images/slider/02.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="swipeinner container">
              <div className="row justify-content-center">
                <div className="col-lg-9 col-md-11 text-center position-relative">
                  <h1
                    data-swiper-animation="fadeInUp"
                    data-duration="1s"
                    data-delay="0.25s"
                  >
                    A BUSINESS SUPPORT COMPANY YOU CAN TRUST
                  </h1>
                  <h6
                    data-swiper-animation="fadeInUp"
                    data-duration="1s"
                    data-delay="0.5s"
                  >
                    We're Lilysolutions. We help drive change with technology.
                  </h6>
                  <Link
                    className="btn btn-dark btn-round text-white"
                    data-swiper-animation="fadeInUp"
                    data-duration="1s"
                    data-delay="0.75s"
                    to="/contact"
                  >
                    View Our Solution
                    <i className="fas fa-arrow-right ps-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="swiper-button-prev">
          <i className="fas fa-arrow-left icon-btn" />
        </div>
        <div className="swiper-button-next">
          <i className="fas fa-arrow-right icon-btn" />
        </div> */}
      </div>
    </section>
  );
};

export default Banner;
