import React from "react";

const Numbers = () => {
  return (
    <section className="py-4 bg-transparant border">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="counter counter-02">
              <div className="counter-icon align-self-center">
                <i className="flaticon-emoji" />
              </div>
              <div className="counter-content align-self-center">
                <span className="timer" data-to={1790} data-speed={10000}>
                  1790
                </span>
                <label>Happy Clients</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="counter counter-02">
              <div className="counter-icon">
                <i className="flaticon-trophy" />
              </div>
              <div className="counter-content">
                <span className="timer" data-to={6} data-speed={10000}>
                  6
                </span>
                <label>Years Active</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="counter counter-02">
              <div className="counter-icon">
                <i className="flaticon-strong" />
              </div>
              <div className="counter-content">
                <span className="timer" data-to={491} data-speed={10000}>
                  491
                </span>
                <label>Finished Projects</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="counter counter-02">
              <div className="counter-icon">
                <i className="flaticon-icon-149196" />
              </div>
              <div className="counter-content">
                <span className="timer" data-to={1090} data-speed={10000}>
                  1090
                </span>
                <label>Media Posts</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
