import React from "react";
import HeaderImg from "../components/HeaderImg";
import { Link } from "react-router-dom";
import how1 from "../assets/images/how1.jpg";
import how2 from "../assets/images/how2.jpg";
import team1 from "../assets/images/team1.jpg";
import CBoxes from "../components/CBoxes";
import Numbers from "../components/Numbers";
import mission from "../assets/images/mission.jpg";

const Missionandvision = () => {
  return (
    <div>
      <HeaderImg
        title="Driving Innovation, Delivering Excellence"
        disc="Committed to transforming businesses through strategic support and innovative solutions."
      />

      <section className="space-pt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-title text-center">
                <h2>Our Commitment Is Inspiring Success in Every Endeavor</h2>
                <p>
                  We are dedicated to empowering businesses with the tools,
                  strategies, and support they need to thrive in today's
                  competitive landscape. Our mission and vision guide every
                  project we undertake, ensuring we consistently deliver value
                  and excellence.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-6 mb-4 mb-sm-0">
              <div className="feature-info feature-info-style-02 bg-dark text-white">
                <div className="feature-info-icon">
                  <i className="flaticon-eye text-white" />
                  <h4 className="mb-0 ms-4 feature-info-title text-white">
                    Our Vision
                  </h4>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    To be the leading provider of innovative business solutions,
                    recognized for our commitment to excellence, creativity, and
                    client success. We envision a future where every business,
                    regardless of size or industry, can achieve its full
                    potential through our expert support and cutting-edge
                    services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="feature-info feature-info-style-02 bg-primary text-white">
                <div className="feature-info-icon">
                  <i className="flaticon-target text-white" />
                  <h4 className="mb-0 ms-4 feature-info-title text-white">
                    Our Mission
                  </h4>
                </div>
                <div className="feature-info-content">
                  <p className="mb-0">
                    Our mission is to empower businesses by providing
                    comprehensive support services that drive growth, enhance
                    brand visibility, and foster long-term success. We aim to
                    deliver exceptional value through our expertise in digital
                    marketing, web development, branding, and business strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-0" style={{ marginTop: 40 }}>
          <div className="row g-0">
            <div className="col-sm-12">
              <img
                className="img-fluid"
                src={mission}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row d-lg-flex align-items-center justify-content-center pb-4 pb-md-5">
            <div className="col-lg-6">
              <h2 className="mb-3 mb-lg-0">
                Three reasons why you should choose our service
              </h2>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link
                to="/contact"
                className="btn btn-light-round btn-round w-space"
              >
                Let’s Get Started
                <i className="fas fa-arrow-right ps-3" />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="feature-info feature-info-style-07">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    src={how1}
                    style={{ height: 250, width: "100%", objectFit: "cover" }}
                    alt=""
                  />
                </div>
                <div className="feature-info-content">
                  <h4 className="feature-info-title">
                    Expertise Across Multiple Domains
                  </h4>
                  <p>
                    Our team comprises of seasoned professionals with extensive
                    experience in various fields, including digital marketing,
                    web development, branding, and business support. This
                    diverse expertise allows us to offer holistic solutions
                    tailored to your specific needs.
                  </p>
                  {/* <a href="#" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="feature-info feature-info-style-07">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    src={team1}
                    style={{ height: 250, width: "100%", objectFit: "cover" }}
                    alt=""
                  />
                </div>
                <div className="feature-info-content">
                  <h4 className="feature-info-title">
                    Client-Centric Approach
                  </h4>
                  <p>
                    At Lilysolutions Limited, we prioritize our client's goals
                    and challenges. Our client-centric approach ensures that we
                    develop customized strategies and solutions that align with
                    your vision and drive measurable results.
                  </p>
                  {/* <a href="#" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="feature-info feature-info-style-07">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    src={how2}
                    style={{ height: 250, width: "100%", objectFit: "cover" }}
                    alt=""
                  />
                </div>
                <div className="feature-info-content">
                  <h4 className="feature-info-title">
                    Proven Track Record of Success
                  </h4>
                  <p>
                    Meet our institute leaders and the hard-working
                    personalities who deliver innovative concepts to
                    corporations like yours.
                  </p>
                  {/* <a href="#" className="icon-btn">
                    <i className="fas fa-long-arrow-alt-right" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ marginBottom: "40px" }}>
        <Numbers />
      </div>

      <CBoxes />
    </div>
  );
};

export default Missionandvision;
