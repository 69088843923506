import React from "react";
import HeaderImg from "../components/HeaderImg";
import how1 from "../assets/images/how1.jpg";
import team1 from "../assets/images/team1.jpg";
import team3 from "../assets/images/team3.jpg";
import new1 from "../assets/images/new1.jpeg";
import new2 from "../assets/images/new2.jpeg";
import header from "../assets/images/header.jpg";

const Careers = () => {
  return (
    <div>
      <HeaderImg
        title="Careers"
        disc="Join the Team That's Redefining Business Success."
      />

      <section className="space-ptb">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="row g-0 d-flex align-items-end mb-4 mb-sm-2">
                <div className="col-sm-8 pe-sm-2 mb-4 mb-sm-0">
                  <img className="img-fluid border-radius" src={how1} alt="" />
                </div>
                <div className="col-sm-4">
                  <div className="counter counter-03 py-5">
                    <div className="counter-content">
                      <span className="timer" data-to={491} data-speed={1000}>
                        491
                      </span>
                      <label>Projects Complete </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-sm-6">
                  <img className="img-fluid border-radius" src={team1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-xl-5">
              <h2 className="mb-4">
                Elevate Your Career with Lily Solutions Limited
              </h2>
              <p className="mb-4">
                At Lilysolutions Limited, we believe in nurturing talent,
                fostering innovation, and providing opportunities for
                professional growth. Join us to be part of a dynamic team that
                values creativity, collaboration, and excellence.
              </p>
              <ul className="list list-unstyled ckeck-list">
                <li className="d-flex">
                  <i className="fas fa-check pe-2 pt-1 text-primary" />
                  <span>Success is something of which we all want more</span>
                </li>
                <li className="d-flex">
                  <i className="fas fa-check pe-2 pt-1 text-primary" />
                  <span>Most people believe that success is difficult</span>
                </li>
                <li className="d-flex">
                  <i className="fas fa-check pe-2 pt-1 text-primary" />
                  <span>
                    There are basically six key areas to higher achievement
                  </span>
                </li>
                <li className="d-flex">
                  <i className="fas fa-check pe-2 pt-1 text-primary" />
                  <span>Believing in yourself and those around you</span>
                </li>
                <li className="d-flex">
                  <i className="fas fa-check pe-2 pt-1 text-primary" />
                  <span>Making a decision to do something</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb popup-gallery overflow-hidden">
        <div className="container-fluid">
          <div className="row d-flex align-items-end">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href="images/gallery/01.jpg">
                <img className="img-fluid" src="images/gallery/01.jpg" alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href="images/gallery/02.jpg">
                <img
                  className="img-fluid w-100"
                  src="images/gallery/02.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href="images/gallery/03.jpg">
                <img className="img-fluid" src="images/gallery/03.jpg" alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-3">
              <a className="portfolio-img" href="images/gallery/04.jpg">
                <img className="img-fluid" src="images/gallery/04.jpg" alt="" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 mt-0 mt-lg-3">
              <a className="portfolio-img" href="images/gallery/05.jpg">
                <img className="img-fluid" src="images/gallery/05.jpg" alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href="images/gallery/06.jpg">
                <img
                  className="img-fluid w-100"
                  src="images/gallery/06.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href="images/gallery/07.jpg">
                <img className="img-fluid" src="images/gallery/07.jpg" alt="" />
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-lg-3">
              <a className="portfolio-img" href="images/gallery/08.jpg">
                <img className="img-fluid" src="images/gallery/08.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb bg-light">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <h2 className="mb-3 mb-lg-0">Why Choose a Career with Us?</h2>
            </div>
            <div className="col-lg-6 text-lg-end">
              <a href="#" className="btn btn-white-round btn-round w-space">
                Let’s Get Started
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div>
          <div className="row mt-4 mt-lg-5">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5 mb-lg-0">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    style={{ height: 160, objectFit: "cover" }}
                    src={new1}
                    alt=""
                  />
                </div>
                <div className="feature-info-number mb-0">
                  <span>01</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    ✔Innovative Environment
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  The sad thing is the majority of people have no clue about
                  what they truly want. They have no clarity.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5 mb-lg-0">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    style={{ height: 160, objectFit: "cover" }}
                    src={new2}
                    alt=""
                  />
                </div>
                <div className="feature-info-number mb-0">
                  <span>02</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    ✔Professional Growth
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  What steps are required to get you to the goals? Make the plan
                  as detailed as possible.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    style={{ height: 160, objectFit: "cover" }}
                    src={header}
                    alt=""
                  />
                </div>
                <div className="feature-info-number mb-0">
                  <span>03</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    ✔Impactful Work
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  This is perhaps the single biggest obstacle that all of us
                  must overcome in order to be successful.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-img">
                  <img
                    className="img-fluid"
                    style={{ height: 160, objectFit: "cover" }}
                    src={team3}
                    alt=""
                  />
                </div>
                <div className="feature-info-number mb-0">
                  <span>04</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    ✔Collaborative Culture
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  To make these virtues a habit, Franklin can up with a method
                  to grade himself on his daily actions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9">
              <div className="section-title text-center">
                <h2>Current career opportunities at Lily Solutions Limited</h2>
                <p>
                  Positive pleasure-oriented goals are much more powerful
                  motivators than negative fear-based ones.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4 mt-md-5">
            <div className="col text-center">
              <p> Send us your resume.</p>
              <a
                href="#"
                className="btn btn-primary btn-round text-white w-space"
              >
                Apply
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Careers;
