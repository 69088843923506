import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const socialLink = [
    {
      iconClassName: "fab fa-twitter",
      url: "https://x.com/lilysolutions24",
    },
    {
      iconClassName: "fab fa-facebook-f",
      url: "https://www.facebook.com/lilysolutionsltd",
    },
    {
      iconClassName: "fab fa-linkedin-in",
      url: "https://www.linkedin.com/company/71383989/admin",
    },
    // {
    //   iconClassName: "fab fa-tiktok",
    //   url: "http://tiktok.com/@_sbtv",
    // },
    {
      iconClassName: "fab fa-youtube",
      url: "https://www.youtube.com/channel/UCB5MW3VFYLETNI__GJP4Sbg",
    },
    {
      iconClassName: "fab fa-instagram",
      url: "https://www.instagram.com/lilysolutionsltd?igsh=MWl2OWtvb2Zjbm83cg%3D%3D&utm_source=qr",
    },
  ];

  const listItemStyle = {
    color: "#293745",
    fontWeight: 500,
    paddingBottom: 14,
    display: "block",
  };
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-3">
              <Link to="/">
                <img className="img-fluid" src="images/logo.svg" alt="logo" />
              </Link>
            </div>
            <div className="col-sm-9 text-sm-end mt-4 mt-sm-0">
              <ul className="list-unstyled mb-0 social-icon">
                {socialLink.map((link, i) => {
                  return (
                    <li key={i}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={link.iconClassName} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <hr className="my-4 my-sm-5 pb-0" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
            <h5 className="text-primary mb-2 mb-sm-4">IT Services</h5>
            <div className="footer-link">
              <ul className="list-unstyled mb-0">
                <li style={listItemStyle}>Mobile Development</li>
                <li style={listItemStyle}>Web Development</li>
                <li style={listItemStyle}>Content Management</li>
                <li style={listItemStyle}>UI/UX DESIGN</li>
              </ul>
              <ul className="list-unstyled mb-0"></ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2 mb-4 mb-lg-0">
            <h5 className="text-primary mb-2 mb-sm-4">Portfolio</h5>
            <div className="footer-link">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/portfolio">Recent Jobs</Link>
                </li>

                <li>
                  <Link to="/">Enroll for Training</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2 mb-4 mb-sm-0">
            <h5 className="text-primary mb-2 mb-sm-4">Support</h5>
            <div className="footer-link">
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/our-team">Our Team</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy & Policy</Link>
                </li>
                <li>
                  <Link to="/careers">
                    Careers{" "}
                    <span className="badge bg-success ms-2">We're hiring</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="footer-contact-info">
              <h5 className="text-primary mb-3">Contact Lilysolutions</h5>
              <div className="contact-address">
                <div className="contact-item">
                  <label>Address:</label>
                  <p>
                    Plot 19, Wole Soyinka Avenue, 2nd Avenue, Gwarimpa Estate,
                  </p>
                </div>
                <div className="contact-item">
                  <label>Phone:</label>
                  <h4 style={{ fontSize: 16 }} className="mb-0 fw-bold">
                    <a href="tel:+2349046164015">09046164015</a> <br />
                    <a href="tel:+2348166950800">08166950800</a>
                  </h4>
                </div>
                <div className="contact-item">
                  <label>Email:</label>
                  <a className="text-dark" href="mailto:info@lilysolutions.org">
                    info@lilysolutions.org
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-sm-5 py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <p className="mb-0">
                ©Copyright 2024 Lilysolutions All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
