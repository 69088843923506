import React from "react";

const Termsandconditions = () => {
  return (
    <div>
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-0">
                <h1>Terms and Conditions</h1>
                <p>
                  Our Commitment to Transparency and Trust. By using our
                  services, you agree to comply with our terms and conditions.
                  Please read them carefully to understand your rights and
                  obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-20">
                Clear Terms for a Strong Partnership. We value our relationship
                with you and strive to make our terms as clear and
                straightforward as possible. These terms govern your use of our
                services and our responsibilities towards you. Acceptance of
                Terms By accessing and using our website and services, you
                accept and agree to be bound by these terms and conditions.
              </p>
              <h4 className="mt-4 text-primary"> Services Provided</h4>
              <p className="mb-20">
                TLilysolutions Limited offers a range of services including
                digital marketing, advertising, web development, branding,
                business support, and public and media relations. The specific
                terms related to each service will be detailed in separate
                agreements.
              </p>
              <h4 className="mt-4 text-primary">Use of Services</h4>
              <p className="mb-20">
                You agree to use our services only for lawful purposes and in
                accordance with these terms. You must not use our services in
                any way that could damage our reputation or interfere with other
                users' access to our services
              </p>
              <h4 className="mt-4 text-primary">Intellectual Property</h4>
              <p className="mb-20">
                All content, trademarks, and data on our website, including but
                not limited to text, graphics, logos, icons, designs, and
                software, are the property of Lilysolutions Limited and are
                protected by copyright and other intellectual property laws. You
                may not reproduce, distribute, or create derivative works from
                any of our content without our express written permission.{" "}
              </p>
              <h4 className="mt-4 text-primary"> Privacy Policy</h4>
              <p className="mb-0">
                Your use of our services is also governed by our Privacy Policy,
                which outlines how we collect, use, and protect your personal
                information. By using our services, you consent to the
                collection and use of your data as described in our Privacy
                Policy.
              </p>
              <h4 className="mt-4 text-primary">Payment Terms</h4>
              <p className="mb-0">
                All fees for our services will be specified in the applicable
                service agreement. Payments must be made in accordance with the
                agreed terms.{" "}
              </p>
              <h4 className="mt-4 text-primary"> Termination of Services</h4>
              <p className="mb-0">
                We reserve the right to terminate or suspend your access to our
                services at any time, without notice, if you breach these terms
                or engage in any unlawful or prohibited activity.
              </p>
              <h4 className="mt-4 text-primary"> Limitation of Liability</h4>
              <p className="mb-0">
                Lilysolutions Limited will not be liable for any direct,
                indirect, incidental, special, or consequential damages
                resulting from the use or inability to use our services. Our
                total liability to you for any damages will not exceed the
                amount you paid for the services.
              </p>
              <h4 className="mt-4 text-primary"> Governing Law</h4>
              <p className="mb-0">
                These terms and conditions are governed by and construed in
                accordance with the laws of the jurisdiction in which
                Lilysolutions Limited operates. Any disputes arising out of or
                related to these terms will be subject to the exclusive
                jurisdiction of the courts in that jurisdiction.
              </p>
              <h4 className="mt-4 text-primary"> Changes to Terms</h4>
              <p className="mb-0">
                We may update these terms and conditions from time to time. We
                will notify you of any significant changes by posting the
                updated terms on our website. Your continued use of our services
                after such changes constitutes your acceptance of the new terms.
              </p>
              <h4 className="mt-4 text-primary"> Contact Information</h4>
              <p className="mb-0">
                If you have any questions or concerns about these terms and
                conditions, please contact us at
              </p>
              <li>Lilysolutions Limited</li>
              <li>Email: info@lilysolutions.org</li>
              <li>Phone: 09046164015, 08166950800</li>
              <p />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Termsandconditions;
