import React from "react";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <div>
      <section
        className="header-inner bg-overlay-black-50 headerImgAlt"
        // style={{ backgroundImage: 'url("images/header-inner/05.jpg")' }}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="header-inner-title text-center position-relative">
                <h1 className="text-white fw-normal">
                  Frequently Asked Questions
                </h1>
                <p className="text-white mb-0">
                  Find answers to common queries about our services, processes,
                  and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-9">
              <div className="accordion" id="career-opportunities">
                <div className="card">
                  <div className="accordion-icon card-header" id="headingOne">
                    <h4 className="mb-0">
                      <button
                        className="btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#security-manager"
                        aria-expanded="true"
                        aria-controls="security-manager"
                      >
                        IWhat services does Lilysolutions Limited offer?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="security-manager"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body">
                      <p className="mb-4">
                        We offer a range of business support services, including
                        digital marketing and advertising, web development,
                        branding, business support, and public and media
                        relations. Our goal is to help your business grow and
                        succeed through tailored, innovative solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingTwo">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#business-partner"
                        aria-expanded="false"
                        aria-controls="business-partner"
                      >
                        How can digital marketing services from Lilysolutions
                        Limited benefit my business?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="business-partner"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body">
                      <p className="mb-4">
                        Our digital marketing services enhance your online
                        presence, engage your target audience, and drive
                        measurable results. We provide strategic marketing
                        campaigns, paid media, influencer marketing, and social
                        media management to boost your brand visibility and
                        growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingThree">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#outreach-representative"
                        aria-expanded="false"
                        aria-controls="outreach-representative"
                      >
                        What is your approach to web development?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="outreach-representative"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body">
                      <p className="mb-4">
                        Our web development approach focuses on creating bespoke
                        websites and robust web applications that are visually
                        appealing, user-friendly, and highly functional. We
                        offer services in e-commerce solutions, front-end
                        development, and custom web applications to meet your
                        specific needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingFour">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#salesforce-developer"
                        aria-expanded="false"
                        aria-controls="salesforce-developer"
                      >
                        How do you ensure the quality of your branding services?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="salesforce-developer"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body">
                      <p className="mb-4">
                        We ensure the quality of our branding services by
                        combining creative design with strategic thinking. Our
                        services include logo design, UI/UX design, visual
                        identity design, and brand collateral development. We
                        work closely with you to create a cohesive and impactful
                        brand identity that resonates with your audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingFive">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#development-manager"
                        aria-expanded="false"
                        aria-controls="development-manager"
                      >
                        What is included in your business support and public
                        relations services?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="development-manager"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        Our business support and public relations services
                        include business strategy consulting, media relations,
                        and corporate communications. We provide expert guidance
                        and strategic insights to optimize your business
                        processes, enhance brand visibility, and manage
                        corporate reputation effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingSix">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#started-manager"
                        aria-expanded="false"
                        aria-controls="started-manager"
                      >
                        How can I get started with Lilysolutions Limited
                      </button>
                    </h4>
                  </div>
                  <div
                    id="started-manager"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        Getting started with us is simple. Contact us through
                        our website or call our office. We will discuss your
                        needs, understand your goals, and develop a tailored
                        plan to help your business succeed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingSeven">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-manager"
                        aria-expanded="false"
                        aria-controls="service-manager"
                      >
                        Do you offer customized service packages?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="service-manager"
                    className="collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        Yes, we offer customized service packages to meet the
                        unique needs of your business. Whether you need a
                        comprehensive solution or specific services, we can
                        create a package that aligns with your goals and budget.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="accordion-icon card-header" id="headingEight">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#campaigns-manager"
                        aria-expanded="false"
                        aria-controls="campaigns-manager"
                      >
                        How do you measure the success of your campaigns and
                        projects?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="campaigns-manager"
                    className="collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        We measure the success of our campaigns and projects
                        through various metrics and KPIs, including engagement
                        rates, conversion rates, ROI, and client feedback.
                        Regular reporting and analysis help us optimize our
                        strategies for maximum impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingNine">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#examples-manager"
                        aria-expanded="false"
                        aria-controls="examples-manager"
                      >
                        Can you provide examples of your previous work?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="examples-manager"
                    className="collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        Yes, we can provide case studies and examples of our
                        previous work upon request. Our portfolio showcases the
                        successful projects we have completed for clients across
                        different industries, highlighting our expertise and
                        results-driven approach.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="accordion-icon card-header" id="headingTen">
                    <h4 className="mb-0">
                      <button
                        className="btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Limited-manager"
                        aria-expanded="false"
                        aria-controls="Limited-manager"
                      >
                        How do I join the Lilysolutions Limited team?
                      </button>
                    </h4>
                  </div>
                  <div
                    id="Limited-manager"
                    className="collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#career-opportunities"
                  >
                    <div className="card-body pb-0">
                      <p className="mb-4">
                        We are always looking for talented and passionate
                        individuals to join our team. Visit our 'Careers' page
                        on the website to explore current job openings and
                        submit your application. We look forward to welcoming
                        you to our team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mt-md-5">
                <div className="col text-center">
                  <p>Don’t see a role that fits? Send us your resume.</p>
                  <Link
                    to="/contact"
                    className="btn btn-primary btn-round text-white w-space"
                  >
                    Contact Us
                    <i className="fas fa-arrow-right ps-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
