import React from "react";

const Comingsoon = () => {
  return (
    <div style={{ marginBottom: 100 }}>
      {/* <header className="header header-transparent pt-0 pt-lg-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <a className="navbar-brand d-inline-block" href="index.html">
                <img
                  className="img-fluid"
                  src="images/logo-light.svg"
                  alt="logo"
                />
              </a>
            </div>
          </div>
        </div>
      </header> */}

      <section
        className="coming-soon py-0 comingBg"
        style={{ height: 1200, padding: 50 }}
        // style={{ backgroundImage: "url(images/bg/08.jpg)" }}
      >
        <div className="container h-100">
          <div className="row justify-content-between h-100">
            <div className="col-xl-5 col-md-6 align-self-center mb-4 mb-md-0">
              <div
                className="countdown-wrapper bg-overlay-black-20 comingBgAlt"
                style={{
                  // backgroundImage: "url(images/bg/09.jpg)",
                  backgroundSize: "cover",
                }}
              >
                <div className="countdown bg-primary">
                  <span className="text-white days">00</span>
                  <p className="text-white days_ref">days</p>
                </div>
                <div className="countdown bg-dark">
                  <span className="text-white hours">00</span>
                  <p className="text-white hours_ref">hours</p>
                </div>
                <div className="countdown bg-white">
                  <span className="text-dark minutes">00</span>
                  <p className="text-dark minutes_ref">minutes</p>
                </div>
                <div className="countdown countdown-last">
                  <span className="text-white seconds">00</span>
                  <p className="text-white seconds_ref">seconds</p>
                </div>
              </div>
              <p className="mb-0 mt-4 font-italic text-white">
                *We are currently working on a website and won't take long.
              </p>
            </div>
            <div className="col-md-6 align-self-center">
              <div
                style={{
                  background: "#fff",
                  display: "inline-block",
                  padding: 20,
                  marginBottom: 30,
                  borderRadius: 10,
                }}
              >
                <img
                  src="images/client-logo/08.svg"
                  alt=""
                  style={{ width: 200 }}
                />
              </div>
              <h2 className="mb-0 text-white">Launching</h2>
              <h1 className="text-white">Very Soon</h1>
              {/* <p className="text-white">
                Provide Your Email Address &amp; We Will Notify You When Site Is
                Ready:
              </p> */}
              {/* <div className="mt-4">
                <form>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <a
                    href="#"
                    className="btn btn-primary-round text-white btn-round"
                  >
                    Subscribe
                    <i className="far fa-paper-plane ps-2" />
                  </a>
                </form>
              </div> */}
            </div>
            <div className="col-xl-5 col-md-6 align-self-center mb-4 mb-md-0 gapSmall">
              <div
                className="countdown-wrapper bg-overlay-black-20 comingBgAlt"
                style={{
                  // backgroundImage: "url(images/bg/09.jpg)",
                  backgroundSize: "cover",
                }}
              >
                <div className="countdownAlt countdown bg-primary">
                  <span className="text-white days">00</span>
                  <p className="text-white days_ref">days</p>
                </div>
                <div className="countdownAlt countdown bg-dark">
                  <span className="text-white hours">00</span>
                  <p className="text-white hours_ref">hours</p>
                </div>
                <div className="countdownAlt countdown bg-white">
                  <span className="text-dark minutes">00</span>
                  <p className="text-dark minutes_ref">minutes</p>
                </div>
                <div className="countdownAlt countdown countdown-last">
                  <span className="text-white seconds">00</span>
                  <p className="text-white seconds_ref">seconds</p>
                </div>
              </div>
              <p className="mb-0 mt-4 font-italic text-white">
                *We are currently working on a website and won't take long.
              </p>
            </div>
            <div className="col-md-6 align-self-center">
              <div
                style={{
                  background: "#fff",
                  display: "inline-block",
                  padding: 20,
                  marginBottom: 30,
                  borderRadius: 10,
                }}
              >
                <img
                  src="images/Downloads/Order Food Logo .png"
                  alt=""
                  style={{ width: 170 }}
                />
              </div>

              <h2 className="mb-0 text-white">Launching</h2>
              <h1 className="text-white">Very Soon</h1>
              {/* <p className="text-white">
                Provide Your Email Address &amp; We Will Notify You When Site Is
                Ready:
              </p> */}
              {/* <div className="mt-4">
                <form>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <a
                    href="#"
                    className="btn btn-primary-round text-white btn-round"
                  >
                    Subscribe
                    <i className="far fa-paper-plane ps-2" />
                  </a>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Comingsoon;
