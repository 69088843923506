import React from "react";
import yt3 from "../assets/images/yt3.png";
import client from "../assets/images/client.jpeg";

const Testemonial = () => {
  const logos = [
    "images/Downloads/LOGOS/logo1.png",
    "images/Downloads/LOGOS/logo2.png",
    "images/Downloads/LOGOS/logo3.png",
    "images/Downloads/LOGOS/logo4.png",
    "images/Downloads/LOGOS/logo5.png",
    "images/Downloads/LOGOS/logo6.png",
    "images/Downloads/LOGOS/logo7.png",
    "images/Downloads/LOGOS/logo8.png",
    "images/Downloads/LOGOS/logo9.png",
  ];

  return (
    <div>
      <section className="py-6 bg-overlay-black-90 bg-holder headerImg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title position-relative">
                <h2 className="text-white">Our Testimonials</h2>
                <p className="text-white">
                  We are happy to find that our service is such a success and
                  can be so profitable to many people and enterprise clients.
                </p>
              </div>
            </div>
          </div>
          <div className="row  mt-5">
            <div className="col-lg-12">
              <div className="brand-logo">
                <div
                  className="owl-carousel testimonial-center owl-nav-bottom-center"
                  data-nav-arrow="false"
                  data-items={5}
                  data-md-items={4}
                  data-sm-items={4}
                  data-xs-items={3}
                  data-xx-items={2}
                  data-space={40}
                  data-autoheight="true"
                >
                  {logos.map((logo) => {
                    return (
                      <div
                        style={{ background: "#fff", borderRadius: 10 }}
                        key={logo}
                        className="item"
                      >
                        <img
                          className="img-fluid center-block mx-auto"
                          src={logo}
                          alt=""
                        />
                      </div>
                    );
                  })}
                  {/* <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/02.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/03.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/04.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/05.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/06.svg"
                      alt=""
                    />
                  </div>
                  <div className="item">
                    <img
                      className="img-fluid center-block mx-auto"
                      src="images/client-logo/light/07.svg"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pt">
        <div className="container">
          <div className="row mb-0 mb-lg-4">
            <div className="col-lg-6 text-center">
              <div className="testimonial-style-02 h-100 shadow-none border">
                <div className="item">
                  <div className="video-image">
                    <img
                      className="img-fluid w-100"
                      src={yt3}
                      style={{ height: 400, objectFit: "cover" }}
                      alt=""
                    />
                    {/* <div
                      style={{
                        height: 400,
                        background: "#bababa",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                      }}
                    ></div> */}

                    <a
                      className="popup-icon popup-youtube"
                      href="https://www.youtube.com/watch?v=cWJGIFFulyw"
                    >
                      <i className="fa fa-play" />
                      <div className="svg-item">
                        <svg
                          version="1.1"
                          id="Layer_2"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          height="48px"
                          viewBox="0 0 1920 48"
                          style={{ enableBackground: "new 0 0 1920 48" }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            id="XMLID_1_"
                            className="st0"
                            fill="#ffffff"
                            points="1920,48 0,48 0,48 1920,0 "
                          />
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div className="testimonial-item-sm pb-4">
                    <div className="testimonial-avatar-sm">
                      <img
                        className="img-fluid rounded-circle"
                        src={client}
                        style={{ height: 90 }}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content text-white">
                      <p>
                        We extend our heartfelt gratitude to Lilysolutions for
                        their exceptional branding and customized video ads that
                        truly bring our vision to life.
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">- Dr. Gbadewole Amos Kayode</h6>
                        <span>
                          MD/CEO, Crown Allied Global Realty & Homes Limited
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="testimonial-style-03 border-radius bg-light p-4 mb-4">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">
                      I want to express my sincere appreciation for
                      Lilysolutions, who came through when I was at a standstill
                      with rebranding my business. Transitioning from Shoeplanet
                      to Naijahands, a multi-level application, was made
                      seamless with their incredible support. The video ads,
                      customized web app, and promotional materials exceeded my
                      expectations. I will definitely keep referring them!
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- Mr. Andrew Etim</h6>
                      <span>MD/CEO, Naijahands / Shoeplanet Limited</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-style-03 border-radius bg-light p-4">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">
                      I discovered Lilysolutions through their Instagram page
                      and decided to try their services. I’m thrilled with the
                      website and full branding package they delivered. I will
                      always recommend them!
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- Otoibhi Emmanuel</h6>
                      <span>CEO/MD, Bitmooregroup</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial-style-03 border-radius bg-light p-4 mt-4 mt-lg-0">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">
                      At Atabala Nigeria Limited, I’m thrilled to share that
                      Lilysolutions handled all my branding needs, from the
                      company brochure to staff recruitment, website
                      development, business logo, content creation, and
                      registration. They truly make the process easier for me.
                      These guys are exceptional at what they do!
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- Atabala Nigeria Limited</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-4">
              <div
                className="testimonial-style-03 border-radius p-4 h-100 bg-overlay-black-70 bg-holder"
                style={{ background: "#022D62" }}
              >
                <div className="testimonial-item p-2 position-relative z-index-1">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      LilyADD is the best platform for anyone and everyone. They
                      offer quality services and also pay for working with them.
                      <br />I love their work, Nice love it keep the hard work.
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">- EMMANUEL O.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className="testimonial-style-03 border-radius p-4 bg-primary">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      I really recommend this to everyone. I in a million
                      opportunity for everyone to earn some money. Thank you
                      lilyadd for this opportunity.
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">- Samuel Isaiah</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-style-03 border-radius bg-light p-4 mt-4">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes text-dark" />
                    <p className="testimonial-des">
                      Number one digital marketing platform in Nigeria, very
                      good.
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">- ECOH MONDAY</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-4">
              <div className="testimonial-style-03 border-radius bg-dark p-4 h-100">
                <div className="testimonial-item p-2">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left quotes" />
                    <p className="text-white testimonial-des">
                      Lilysolutions has the best platform. Lilysolutions did
                      exactly what you said it does. I will let my mum know
                      about this, she could really make use of Lilysolutions!
                      Thank you for making it painless, pleasant and most of all
                      hassle free!.
                    </p>
                    <p className="text-white testimonial-des">
                      The best platform ever, keep up the good work 💯👌✅
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1 text-white">- Abdsalam A. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testemonial;
