import React from "react";
import { Link, useLocation } from "react-router-dom";

const HeaderImg = ({ title, disc }) => {
  const { pathname } = useLocation();

  const links = [
    {
      name: "About Us",
      url: "/about",
    },
    {
      name: "Careers",
      url: "/careers",
    },
    {
      name: "How We Work",
      url: "/how-we-work",
    },
    {
      name: "Our Team",
      url: "/our-team",
    },
    {
      name: "Mission Vision",
      url: "/mission-vision",
    },
    {
      name: "Our Value",
      url: "/our-value",
    },
  ];
  return (
    <section
      className="header-inner header-inner-menu bg-overlay-black-50 headerImg"
      //   style={{ backgroundImage: '' }}
    >
      <div className="container position-relative">
        <div className="row d-flex justify-content-center position-relative">
          <div className="col-md-8">
            <div className="header-inner-title text-center">
              <h1 className="text-white fw-normal">{title}</h1>
              <p className="text-white mb-0">{disc}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-inner-nav">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <ul className="nav">
                {links.map((link) => {
                  return (
                    <li key={link.name} className="nav-item">
                      <Link
                        className={
                          pathname === link.url ? "nav-link active" : "nav-link"
                        }
                        to={link.url}
                      >
                        {link.name}
                      </Link>
                    </li>
                  );
                })}
                {/* <li className="nav-item">
                  <a className="nav-link" href="careers.html">
                    Careers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="how-we-work.html">
                    How we work
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="our-team.html">
                    Our team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="mission-vision.html">
                    Mission and vision
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="our-value.html">
                    Our values
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderImg;
