const Leaders = () => {
  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 text-center">
            <div
              className="owl-carousel testimonial"
              data-nav-arrow="true"
              data-nav-dots="false"
              data-items={1}
              data-lg-items={1}
              data-md-items={1}
              data-sm-items={1}
              data-space={0}
              data-autoheight="true"
            >
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar shadow">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/team/15.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-content">
                    <p>
                      "Creativity is about seeing what everyone else has seen
                      and thinking what no one else has thought."
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">Sherry Joy Lanre</h6>
                      <span>Creative Director</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar shadow">
                    <img
                      className="img-fluid rounded-circle"
                      src="images/team/01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="testimonial-content">
                    <p>
                      "Innovation and dedication are at the core of everything
                      we do at Lilysolutions."
                    </p>
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <h6 className="mb-1">Lanre Olifade</h6>
                      <span> Founder/CEO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Leaders;
