import React from "react";
import { Link } from "react-router-dom";

const CBoxes = () => {
  return (
    <section className="space-pb">
      <div className="container">
        <div className="row dark-background">
          <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
            <div className="feature-info feature-info-style-04">
              <div className="feature-info-content">
                <h4 className="mb-3 fw-normal feature-info-title">Careers</h4>
                <p>
                  Walkout 10 years into your future and feel how it feels to
                  carry on doing the same thing.
                </p>
                <Link
                  to="/careers"
                  className="btn btn-primary-round btn-round text-white"
                >
                  View Positions
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
          <div className="feature-info feature-info-style-04">
            <div className="feature-info-content">
              <h4 className="mb-3 fw-normal feature-info-title">
                Latest News
              </h4>
              <p>
                This path is just like today, with one difference: you have
                10 fewer years remaining in your life.
              </p>
              <a
                href="#"
                className="btn btn-primary-round btn-round text-white"
              >
                Read Articles
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div>
        </div> */}
          <div className="col-lg-4">
            <div className="feature-info feature-info-style-04">
              <div className="feature-info-content">
                <h4 className="mb-3 fw-normal feature-info-title">Contact</h4>
                <p>
                  I want you to think about how you will feel in 10 years if you
                  continue doing the exact same things.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary-round btn-round text-white"
                >
                  Get in Touch
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CBoxes;
