import React from "react";

const Awards = () => {
  return (
    <section className="space-pb our-clients">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-4 mb-4 mb-md-0">
            <h5 className="text-primary mb-0">Awards and Nominees</h5>
          </div>
          <div className="col-xl-9 col-md-8">
            <div
              className="owl-carousel"
              data-nav-arrow="false"
              data-items={4}
              data-md-items={4}
              data-sm-items={4}
              data-xs-items={3}
              data-xx-items={2}
              data-space={40}
              data-autoheight="true"
            >
              <div className="item">
                <img
                  className="img-fluid w-75 grayscale"
                  src="images/award-logo/01.svg"
                  alt=""
                />
              </div>
              {/* <div className="item">
                  <img
                    className="img-fluid w-75 grayscale"
                    src="images/award-logo/02.svg"
                    alt=""
                  />
                </div> */}
              <div className="item">
                <img
                  className="img-fluid w-75 grayscale"
                  src="images/award-logo/03.svg"
                  alt=""
                />
              </div>
              <div className="item">
                <img
                  className="img-fluid w-75 grayscale"
                  src="images/award-logo/04.svg"
                  alt=""
                />
              </div>
              <div className="item">
                <img
                  className="img-fluid w-75 grayscale"
                  src="images/award-logo/05.svg"
                  alt=""
                />
              </div>
              {/* <div className="item">
                  <img
                    className="img-fluid w-75 grayscale"
                    src="images/award-logo/06.svg"
                    alt=""
                  />
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
