import React from "react";
import CBoxes from "../components/CBoxes";

const Contact = () => {
  return (
    <div>
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h1>Contact us</h1>
                <p>
                  Drop us a line! We’ll set up a time to chat over the phone or
                  in-person to know more about your business needs. All primary
                  conferences are free of charge.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-lg-around position-relative pt-5">
            <div className="col-lg-4 col-md-5 mb-4">
              <div className="is-sticky">
                <h4 className="mb-4">
                  Let’s talk about what you want to accomplish and how we can
                  make it happen.
                </h4>
                <h5 className="text-light">
                  This is the beginning of creating the life that you want to
                  live.
                </h5>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 pe-lg-5">
              <div className="p-4 p-md-5 bg-white shadow">
                <h3>Need assistance? please fill the form</h3>
                <div className="mt-4">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      placeholder="Name"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputLname"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEnquiry"
                      placeholder="Enquiry Type"
                    />
                  </div>
                  <div className="mb-4">
                    <textarea
                      className="form-control"
                      id="exampleInputEnquiry-Description"
                      placeholder="Enquiry Description"
                      rows={5}
                      defaultValue={""}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="form-check ms-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I agree to talk about my project with Lilysolutions
                      </label>
                    </div>
                  </div>
                  <div className="mb-0">
                    <button type="submit" className="btn btn-primary">
                      Send Massage
                      <i className="fas fa-arrow-right ps-3" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-bg-logo text-center">
              <i className="fas fa-comment" />
            </div>
          </div>
        </div>
      </section>

      <CBoxes />
    </div>
  );
};

export default Contact;
