import React from "react";
import HeaderImg from "../components/HeaderImg";
import team1 from "../assets/images/team1.jpg";
import team2 from "../assets/images/team2.jpg";
import team3 from "../assets/images/team3.jpg";
import adam from "../assets/images/adam.jpg";
import staff1 from "../assets/images/staff1.jpg";
import staff2 from "../assets/images/staff2.jpg";
import Kingsley from "../assets/images/kingsley.jpeg";
import david from "../assets/images/david.jpg";
import { Link } from "react-router-dom";
import Leaders from "../components/Leaders";
import Awards from "../components/Awards";
import CBoxes from "../components/CBoxes";

const Ourteam = () => {
  const team = [
    {
      name: "Lanre Olifade",
      position: "Co-Founder/CEO",
      url: "images/team/01.jpg",
    },
    {
      name: "Sherry Joy Lanre",
      position: "Creative Director",
      url: "images/team/15.jpeg",
    },
    // {name: "Justice Oguche", position: "Fullstack Developer ", url: "images/team/04.jpg"},
    {
      name: "Giwa Muhammad",
      position: "Backend Engineer",
      url: "images/team/03.jpg",
    },
    {
      name: "Adam Abdullahi Araga",
      position: "Fullstack Developer",
      url: adam,
    },
    {
      name: "Kingsley Okon",
      position: "Full Stack Developer",
      url: Kingsley,
    },
    {
      name: "Oluwatofunmi Charles Matthews",
      position: "Intern/Developer",
      url: "images/team/12.jpg",
    },
    {
      name: "Ikpemi Agweda",
      position: "Business Development Manager",
      url: "images/team/09.jpg",
    },
    {
      name: "WILLIAM CHIDIEBERE",
      position: "Business Development Officer",
      url: "images/team/07.jpg",
    },
    {
      name: "Blessing Okafor",
      position: "Business Executive Officer",
      url: "images/team/08.jpg",
    },
    {
      name: "Kelvin Elisha",
      position: "Photographer/Photo Editor",
      url: "images/team/10.jpg",
    },
    {
      name: "Noble Ogochuwku",
      position: "Graphic Designer",
      url: "images/team/14.jpg",
    },
    {
      name: "David O. Ayomide",
      position: "Video Editor/Cinematographer",
      url: david,
    },
    {
      name: "Imafiabor Josephine",
      position: "Business Executive Officer",
      url: "images/team/13.jpg",
    },
    {
      name: "Francisca Emeakayi",
      position: "Office Assistant",
      url: staff1,
    },
    {
      name: "Miracle Emmanuel",
      position: "Social Media Specialistt",
      url: staff2,
    },
  ];
  return (
    <div>
      <HeaderImg
        title="Meet Our Team"
        disc="Meet the Minds Behind Our Success"
      />

      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h2 className="mb-3">
                  Our Passionate Team is Committed to Your Success
                </h2>
                <p>
                  At Lilysolutions Limited, our team is our greatest asset. Each
                  member brings unique expertise and a shared commitment to
                  achieving outstanding results for our clients. Get to know the
                  talented individuals who make it all happen.
                </p>
              </div>
              {/* <a href="#" className="btn btn-light-round btn-round w-space">
                Know More About
                <i className="fas fa-arrow-right ps-3" />
              </a> */}
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <img
                    className="img-fluid border-radius mb-4 mt-4"
                    src={team1}
                    alt=""
                  />
                  <img
                    className="img-fluid border-radius mb-4 mb-sm-0"
                    src={team2}
                    alt=""
                  />
                </div>
                <div className="col-sm-6">
                  <img
                    className="img-fluid border-radius mb-4"
                    src={team3}
                    alt=""
                    style={{ height: 300, objectFit: "cover" }}
                  />
                  <div className="counter counter-03">
                    <div className="counter-content">
                      <span className="timer" data-to={491} data-speed={10000}>
                        339
                      </span>
                      <label>Projects Complete</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb overflow-hidden">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-title text-center">
                <h2>Meet our heroes</h2>
                <p className="lead">
                  Our team is friendly, talkative, and fully reliable.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {team.map((item, i) => {
              return (
                <div key={i} className="col-xl-2 col-md-3 col-sm-4 col-6">
                  <div className="team">
                    <div className="team-bg" />
                    <div className="team-img">
                      <img
                        className="img-fluid"
                        style={{ height: 200, objectFit: "cover" }}
                        src={item.url}
                        alt=""
                      />
                    </div>
                    <div className="team-info">
                      <span className="team-name">{item.name}</span>
                      <p>{item.position}</p>
                      <ul className="list-unstyled">
                        <li>
                          <span>
                            <i className="fab fa-facebook-f" />
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="fab fa-twitter" />
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="fab fa-linkedin-in" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="col-xl-2 col-md-3 col-sm-4 col-6">
              <div className="team apply-position">
                <div className="team-icon">
                  <i className="far fa-user-circle" />
                </div>
                <div className="team-info">
                  <Link to="/careers" className="btn btn-link">
                    Apply for Position
                    <i className="fas fa-arrow-right text-dark ps-1" />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="space-pb pt-4 pt-md-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 text-center">
              <div
                className="owl-carousel testimonial"
                data-nav-arrow="true"
                data-nav-dots="false"
                data-items={1}
                data-lg-items={1}
                data-md-items={1}
                data-sm-items={1}
                data-space={0}
                data-autoheight="true"
              >
                <div className="item">
                  <div className="testimonial-item">
                    <div className="testimonial-avatar shadow">
                      <img
                        className="img-fluid rounded-circle"
                        src="images/avatar/01.jpg"
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        "Innovation and dedication are at the core of everything
                        we do at Lilysolutions."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">Lanre Olifade</h6>
                        <span>Founder/CEO</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimonial-item">
                    <div className="testimonial-avatar shadow">
                      <img
                        className="img-fluid rounded-circle"
                        src="images/avatar/02.jpg"
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        "Creativity is about seeing what everyone else has seen
                        and thinking what no one else has thought."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">Joy Lanre</h6>
                        <span>Creative Director</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb our-clients">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 mb-md-0">
              <h5 className="text-primary mb-0">Awards and Nominees</h5>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-8">
              <div
                className="owl-carousel testimonial-center owl-nav-bottom-center"
                data-nav-arrow="false"
                data-items={5}
                data-md-items={4}
                data-sm-items={4}
                data-xs-items={3}
                data-xx-items={2}
                data-space={40}
                data-autoheight="true"
              >
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/01.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/02.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/03.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/04.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/05.svg"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    className="img-fluid grayscale"
                    src="images/award-logo/06.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Leaders />

      <Awards />

      <CBoxes />
    </div>
  );
};

export default Ourteam;
