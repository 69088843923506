import React from "react";
import HeaderImg from "../components/HeaderImg";
import Awards from "../components/Awards";
import CBoxes from "../components/CBoxes";

const Ourvalue = () => {
  return (
    <div>
      <HeaderImg
        title="Guided by Integrity, Driven by Excellence"
        disc="At Lilysolutions Limited, our core values shape every decision we make and every action we take"
      />

      <section className="space-ptb pb-md-5 bg-dark">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-lg-10 text-center text-white">
              <h2 className="text-white mb-4">
                Our Values Is The Foundation of Our Success
              </h2>
              <p className="mb-0">
                {" "}
                Our values are at the heart of everything we do at Lilysolutions
                Limited. They guide our interactions with clients, partners, and
                each other, ensuring we consistently deliver outstanding results
                while fostering a positive and ethical work environment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark-half-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="position-relative">
                <div
                  style={{ height: 400, background: "#000", borderRadius: 10 }}
                ></div>
                <a
                  className="icon-btn icon-btn-lg icon-btn-all-center btn-animation popup-youtube"
                  href="https://www.youtube.com/watch?v=-Vnq78z4ui0"
                >
                  <i className="fas fa-play fa-1x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>01</span>
                  <h5 className="mb-0 ms-4 feature-info-title">Integrity</h5>
                </div>
                <p className="mt-4 mb-0">
                  We conduct our business with the highest ethical standards,
                  ensuring honesty, transparency, and fairness in all our
                  interactions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>02</span>
                  <h5 className="mb-0 ms-4 feature-info-title">Innovation</h5>
                </div>
                <p className="mt-4 mb-0">
                  We embrace creativity and strive for continuous improvement,
                  leveraging cutting-edge technology and innovative strategies
                  to deliver exceptional solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>03</span>
                  <h5 className="mb-0 ms-4 feature-info-title">Client Focus</h5>
                </div>
                <p className="mt-4 mb-0">
                  Our client's success is our top priority. We are committed to
                  understanding their needs and exceeding their expectations
                  through personalized service and dedicated support.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>04</span>
                  <h5 className="mb-0 ms-4 feature-info-title">Excellence</h5>
                </div>
                <p className="mt-4 mb-0">
                  We are dedicated to delivering superior quality in every
                  project, ensuring that our work meets the highest standards of
                  excellence and professionalism.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5 mb-lg-0">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>05</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Collaboration
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  We believe in the power of teamwork. By fostering a
                  collaborative environment, we harness the diverse talents and
                  perspectives of our team to achieve shared goals.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-5 mb-lg-0">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>06</span>
                  <h5 className="mb-0 ms-4 feature-info-title">
                    Accountability
                  </h5>
                </div>
                <p className="mt-4 mb-0">
                  We take responsibility for our actions and results, holding
                  ourselves accountable to our clients, partners, and each
                  other.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="feature-info feature-info-style-06">
                <div className="feature-info-number mb-0">
                  <span>07</span>
                  <h5 className="mb-0 ms-4 feature-info-title">Respect</h5>
                </div>
                <p className="mt-4 mb-0">
                  We treat everyone with dignity and respect, valuing diverse
                  opinions and fostering an inclusive culture where everyone
                  feels valued and heard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Awards />

      <CBoxes />
    </div>
  );
};

export default Ourvalue;
