import React from "react";

const Privacypolicy = () => {
  return (
    <div>
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-0">
                <h1>Your Privacy Is Our Priority</h1>
                <p>
                  At Lilysolutions Limited, we are committed to protecting your
                  personal information and ensuring your privacy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>Transparency and Trust: Our Commitment to Your Privacy.</p>
              <p>
                We understand the importance of your personal information and
                are dedicated to safeguarding it.{" "}
              </p>
              <p>
                Our privacy policy outlines how we collect, use, and protect
                your data.
              </p>
              <h4 className="mt-4 text-primary">Information We Collect</h4>
              <p className="mt-3">
                We collect personal information that you provide to us directly,
                such as your name, email address, phone number, and Portfolio
                details when you contact us, sign up for our services, or
                request information.
              </p>
              <h4 className="mt-4 text-primary">
                How We Use Your Information.
              </h4>
              <p>
                We use your information to provide and improve our services,
                communicate with you, process your requests, and personalize
                your experience with Lilysolutions Limited. We may also use your
                data for marketing purposes with your consent.
              </p>
              <h4 className="mt-4 text-primary">
                Information Sharing and Disclosure
              </h4>
              <p>
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties without your consent, except to
                trusted partners who assist us in operating our website,
                conducting our business, or serving our clients, as long as
                those parties agree to keep this information confidential.
              </p>
              <h4 className="mt-4 text-primary">Data Security</h4>
              <p className="mt-3">
                We implement a variety of security measures to protect your
                personal information. These measures include secure servers,
                encryption, and regular security audits to prevent unauthorized
                access, alteration, disclosure, or destruction of your data.
              </p>
              <h4 className="mt-4 text-primary">
                Cookies and Tracking Technologies
              </h4>
              <p>
                We use cookies and similar tracking technologies to enhance your
                experience on our website. Cookies help us understand how you
                use our site, personalize your experience, and improve our
                services. You can manage your cookie preferences through your
                browser settings.
              </p>
              <h4 className="mt-4 text-primary">Your Rights and Choices</h4>
              <p>
                You have the right to access, correct, or delete your personal
                information held by us. You can also opt out of receiving
                marketing communications from us at any time. To exercise these
                rights, please contact us using the information provided below.
              </p>
              <h4 className="mt-4 text-primary">Third-Party Links</h4>
              <p>
                Our website may contain links to third-party sites. We are not
                responsible for the privacy practices of these external sites.
                We encourage you to review their privacy policies before
                providing any personal information.
              </p>
              <h4 className="mt-4 text-primary">
                Changes to This Privacy Policy
              </h4>
              <p>
                We may update our privacy policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any significant changes by posting the updated policy on
                our website.
              </p>
              <h4 className="mt-4 text-primary">Contact Us</h4>
              <p>
                If you have any questions or concerns about our privacy policy
                or how we handle your personal information, please contact us
                at:
              </p>
              <p className="mt-3 mb-0">Lilysolutions Limited</p>
              <li>Email: policy@lilysolutions.org</li>
              <li>Phone: 09046164015</li>
              <p />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacypolicy;
