import React, { useEffect, useState } from "react";
import ActionBox from "../components/ActionBox";
import { useSearchParams } from "react-router-dom";
import blog1 from "../assets/images/blog/1.jpg";
import blog2 from "../assets/images/blog/2.jpg";
import blog3 from "../assets/images/blog/3.jpg";
import blog4 from "../assets/images/blog/4.jpg";
import blog5 from "../assets/images/blog/5.jpg";
import blog6 from "../assets/images/blog/6.jpg";

const Blogdetail = () => {
  const [id, setId] = useState(null);
  const [blog, setBlog] = useState(null);
  // const [play, setPlay] = useState(false);

  const [searchParams] = useSearchParams();
  const blogs = [
    {
      id: "hnbtr54t545gtvr54",
      title: "Top 5 Business Branding Mistakes",
      desc: `Branding is a vital part of business, mostly done at the beginning of a 
business venture. However, these are the top 5 mistakes business owners 
make and how to correct them.`,
      img: blog1,
      tag: "Business",
      date: "May 16, 2024",
      youtube: "lexdp7dteX8",
    },
    {
      id: "sdsdfr38744g3h4g3h",
      title: "How to turn your Brand into a Legacy",
      desc: `Starting your brand sometimes have future purposes but sometimes the future is not beyond you. Some brands outlive the visioner while some don’t. If you think your brand is meant to outlive you, you need to watch this video to know how to turn your brand into a legacy that will outlive you and your generation.`,
      img: blog2,
      tag: "Business",
      date: "Feb 23, 2024",
      youtube: "G_pGebmg2s4",
    },
    {
      id: "alkiae7232k2j3e89w32",
      title: "How to Switch from Employee to Business owner",
      desc: `Been an employee is not the end of the road, that your business dream can be born, this transition is possible! Watch Solutions Podcast to know how!`,
      img: blog3,
      tag: "Business",
      date: "Dec 3, 2023",
      youtube: "J_-hfivfUFc",
    },
    {
      id: "gs56d734u873euwrer",
      title: "Why you Need to Work for Someone",
      desc: `Are you young in the labour market or fresh out of school and you are passionate about starting a business. That’s a brilliant idea, but in this episode, we expose to you the reason why you need to work first before going out on your own.`,
      img: blog4,
      tag: "Career",
      date: "Dec 3, 2023",
      youtube: "gsXMaSAC24I",
    },
    {
      id: "fhduf6e643h4jw6e34ew",
      title: "A Key to Thriving Business",
      desc: `Been stuck in life or business is as a result of the lack of fresh ideas. Sam Adeyemi said Ideas rule the world and this is very true. Learn how innovation applies to your life and business and how you can become an idea factory.`,
      img: blog5,
      tag: "Business",
      date: "Dec 3, 2023",
      youtube: "tjIT_wa-Uu0",
    },
    {
      id: "kllksdri7384jkw84233re",
      title: "Waiting for Clients or Getting Clients",
      desc: `Starting a business is one thing, getting clients or making sales is another hurdle. Are you the type that waits for clients or customers? Well find out about these two approaches to marketing and find out the most appropriate for you.`,
      img: blog6,
      tag: "Business",
      date: "Dec 3, 2023",
      youtube: "3HldUtubBHo",
    },
  ];

  const mainId = searchParams.get("id");

  useEffect(() => {
    if (mainId) {
      setId(mainId);
    }
  }, [mainId]);

  useEffect(() => {
    if (id) {
      const newBlog = blogs.filter((cur) => cur.id === id);
      if (newBlog.length > 0) {
        setBlog(newBlog[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log("blog", blog);

  return (
    <div>
      <section
        className="header-inner bg-overlay-black-50 blogBg"
        // style={{ backgroundImage: 'url("images/header-inner/04.jpg")' }}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="header-inner-title text-center position-relative">
                <h1 className="text-white fw-normal mb-0">{blog?.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="blog-detail">
                <div className="blog-post mb-4 mb-md-5">
                  <div className="blog-post-image">
                    {/* <img className="img-fluid" src={blog?.img} alt="" /> */}
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-info">
                      <div className="blog-post-author">
                        <span
                          style={{ cursor: "auto" }}
                          className="btn btn-light-round btn-round text-primary"
                        >
                          {blog?.tag}
                        </span>
                      </div>
                      <div className="blog-post-date">
                        <span>{blog?.date}</span>
                      </div>
                    </div>
                    <div className="blog-post-details">
                      <h5 className="blog-post-title">{blog?.title}</h5>
                      <p className="mb-4">{blog?.desc}</p>
                      {/* <p className="mb-md-5 mb-4">
                        You carry on doing the same things, living the same way
                        and dealing with this thing in the same way as you have
                        been doing. If you were choose the path to the right,
                        the right path, there are new possibilities,
                        achievement, freedom of mind, positive and progressive
                        implications. Think about that as you stand at this
                        place where the path splits. You want to make a decision
                        and commit to one of these paths. Before you make that
                        decision, we are going to see what each path holds for
                        your future.
                      </p>
                      <div className="d-sm-flex bg-light border-radius p-4 p-md-5 mb-md-5 mb-4">
                        <i className="fas fa-quote-left pe-4 fa-6x text-primary" />
                        <p className="mb-0 text-dark">
                          Politics can be attributed to his perseverance to
                          overcome his personal liabilities, and his desire to
                          constantly become better. Next time you really want to
                          achieve something, take time to focus on your own
                          personal journal. What is your temptation that is
                          standing in your wayv to greatness.
                        </p>
                      </div>
                      <ul className="list list-unstyled">
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Use a past defeat as a motivator. Remind yourself
                            you have nowhere to go except
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Give yourself the power of responsibility.{" "}
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Remind yourself the only thing stopping you is
                            yourself.
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Make a list of your achievements toward your
                            long-term
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            goal and remind yourself that intentions don’t
                            count, only action’s.
                          </span>
                        </li>
                      </ul> */}
                    </div>
                    <div class="blog-post mb-4 mb-md-5">
                      <div
                        class="blog-post-image position-relative"
                        style={{ cursor: "pointer" }}
                      >
                        <iframe
                          style={{
                            height: 400,
                            width: "100%",
                            borderRadius: 5,
                          }}
                          // allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="Youtube player"
                          // sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                          src={`https://youtube.com/embed/${blog?.youtube}?autoplay=0`}
                        ></iframe>
                        {/* {play ? (
                          <iframe
                            style={{
                              height: 400,
                              width: "100%",
                              borderRadius: 5,
                            }}
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="Youtube player"
                            // sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={`https://youtube.com/embed/LgvseYYhqU0?autoplay=1&mute=1`}
                          ></iframe>
                        ) : (
                          <>
                            <img
                              class="img-fluid"
                              src={blog?.img}
                              alt=""
                              style={{
                                height: 400,
                                objectFit: "cover",
                                width: "100%",
                              }}
                            />
                            <span
                              class="icon-btn icon-btn-lg icon-btn-all-center btn-animation"
                              onClick={() => setPlay(true)}
                            >
                              <i class="fas fa-play fa-1x"></i>
                            </span>
                          </>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ActionBox />
    </div>
  );
};

export default Blogdetail;
